













import Vue from 'vue';
import { OrgModel } from '@/types/org';
import ApiService from '@/services/api.service';
import Page from '@/layouts/Page.vue';
import AppCard from '@/components/card/AppCard.vue';
import DeleteDivision from '@/views/admin/org/divisions/DeleteDivision.vue';
import EditDivision from '@/views/admin/org/divisions/EditDivision.vue';

function getDefaultModel() {
  return {
    id: '',
    label: '',
    description: '',
    projectsNumber: 0,
  };
}

export default Vue.extend({
  name: 'ViewDivision',
  components: {
    AppCard,
    Page,
    DeleteDivision,
    EditDivision,
  },
  computed: {
    org(): OrgModel {
      return this.$store.getters['orgModule/details'];
    },
    breadcrumbs(): any[] {
      return [
        {
          label: this.org.label,
          url: `/org/${this.org.id}/divisions`,
        },
        {
          label: this.division.label,
        },
      ];
    },
  },
  data() {
    return {
      division: getDefaultModel(),
    };
  },
  methods: {
    async loadDivision() {
      try {
        this.division = await ApiService.get(`/org/${this.org.id}/division/${this.$route.params.id}`);
      } catch {
        this.division = getDefaultModel();
      }
    },
  },
  async created() {
    await this.loadDivision();
  },
});
